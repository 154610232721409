<template>
  <div>
    <div class="form-floating mb-3">
      <input
        v-model.number="rawValue"
        type="number"
        class="form-control" id="" placeholder="0">
      <label for="">{{ nutrientLabel }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NutritionMacronutrientInput',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    nutrientLabel: {
      type: String,
      required: true,
    },
    multiplier: {
      type: Number,
      required: true,
    },
  },
  watch: {
    value(newValue) {
      this.rawValue = Number(newValue);
    },
    rawValue(newValue, oldValue) {
      if (oldValue) {
        this.$emit('rawValueUpdate', newValue);
      }
    },
  },
  data() {
    return {
      rawValue: 0,
    };
  },
  mounted() {
    this.rawValue = Number(this.value);
  },
};
</script>
